@tailwind base;

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000000;
  font-family: 'Gorditas'
}
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'DOS NODE';
  src: url('DOS_NODE.woff2') format('woff2'),
}

.wallet-adapter-button-trigger{
  background-color: white !important;
  width: 85%;
  border: 1px solid #2e2e2e !important;
  border-radius: 25px !important; 
  height: 109px !important;
  text-transform: uppercase;
  font-family: 'Gorditas' !important;
  @media screen and (max-width: 600px) {
    height: 40px !important;
    width: 100%;

  }
}

.wallet-adapter-dropdown{
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


